body {
  -webkit-overflow-scrolling: touch;
  background: #f8f9fa;
}
.css-1rmtqz1 div:nth-child(1) {
  -webkit-box-pack: initial !important;
  -ms-flex-pack: initial !important;
  justify-content: initial !important;
}

.ant-tooltip-inner#kpi-tooltip {
  padding: 10px 20px;
  white-space: pre;
  text-align: center;
  font-size: 16px;
}

.ant-notification {
  z-index: 1;
}

.notification.ant-notification-notice {
  border: 1px solid #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 16px 14px;
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-left: 65px;
    font-weight: bold;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 65px;
  }
}

.tada span .ant-badge-count {
  animation-delay: 151s;
  top: 18px !important;
  right: -5px !important;
  transform: unset;
  animation-duration: 1ms;

  animation: tada 1s infinite linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  //animation-delay: 100s;
  // -webkit-animation-name: tada;
  // animation-name: tada;
  // -webkit-animation-duration: 1s;
  // animation-duration: 1s;
}

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

#confetti {
  width: 100%;
  height: 212px;
  animation-name: confetti;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-timing-function: linear;
  transition: width 0.8s ease-out;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@keyframes confetti {
  0% {
    background: url('../icons/enrollment/confetti-first.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  20% {
    background: url('../icons/enrollment/confetti-second.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  40% {
    background: url('../icons/enrollment/confetti-third.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  60% {
    background: url('../icons/enrollment/confetti-fourth.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  80% {
    background: url('../icons/enrollment/confetti-fifth.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  100% {
    background: url('../icons/enrollment/confetti-sixth.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
