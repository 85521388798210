.ant-modal-wrap {
  overflow: auto !important;
}

.ant-notification {
  z-index: 99999999;
}

.ant-modal-confirm.ant-modal-confirm-success {
  .ant-modal-body {
    text-align: center;
    padding: 0px 0px 0px;
    .ant-modal-confirm-body-wrapper {
      padding-top: 20px;
      .ant-modal-confirm-content {
        background: linear-gradient(270deg, #ffa400 2.12%, #ff6b00 90.61%);
        border-radius: 4px 4px 10px 10px;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
        line-height: 39px;
        padding: 20px 15px;
      }

      .ant-modal-confirm-btns {
        display: none;
      }
    }
  }
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  left: 1px;
}

@media only screen and (max-width: 320px) {
  .ant-modal-content .ant-modal-footer div .ant-btn {
    font-size: 14px !important;
  }
}
