.root {
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 1vw 1px 1vw;
  padding-top: 56px;
  background-color: #fff;
  background-image: url('../../images/enrollment_background.png');
  background-position-x: center;
  background-position-y: -5vh;
  background-size: 400px auto;
  background-repeat: no-repeat;
}
.enrollment-title {
  height: 118px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  margin: 0 1vw;
  .upper-text {
    font-weight: 600;
    font-size: 1.8em;
  }
  .bottom-text {
    font-weight: 900;
    font-size: 2.3em;
  }
  p {
    margin: 0;
  }
}
.edit-enrollment {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5px;
  p {
    margin: 0 1vw;
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
  img {
    height: 26px;
    &:hover {
      cursor: pointer;
    }
  }
}
.bootcamp-selection {
  margin: 0 -1vw 1vh -1vw;
  padding: 5px 10px;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.15);
  .message-container {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 30px;
    width: 100%;
    border: none;
    color: white;
    background: rgb(255, 70, 19);
    background: linear-gradient(90deg, rgba(255, 70, 19, 1) 0%, rgba(255, 107, 0, 1) 100%);
    border-radius: 5px 5px 0 0;
    margin-bottom: 5px;
    span {
      font-weight: 900;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
    }
  }
  .button-text-container {
    text-align: start;
    p {
      margin: 0;
    }
    .button-uppertext {
      font-weight: 600;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
    .button-bottomtext {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 700;
      font-size: 12px;
      text-transform: capitalize;
    }
  }
  .dropdown-container {
    width: 100%;
    padding: 0 5px;
    i {
      color: white;
      background-color: rgba(255, 107, 0, 1);
      font-size: 20px !important;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      svg {
        padding-top: 7px;
        height: 1.3em;
        width: 1.3em;
      }
    }
    button {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px !important;
      border-color: #ff6b00;
      &:hover {
        border-color: #ff6b00;
      }
      p {
        margin: 0;
      }
    }
  }
}
.warning-container {
  border-radius: 5px;
  box-shadow: 0 -1px 8px 2px rgba(0, 0, 0, 0.15);
  .upper-container {
    border-radius: 5px 5px 0 0;
    background-color: #f56154;
    text-align: center;
    padding: 5px 0;
  }
  .warning {
    background-color: #ff8831 !important;
  }
  .text-container {
    padding: 5px 0;
    text-align: center;
    .uppertext {
      font-size: 18px;
      font-weight: 600;
      color: #323232;
      padding: 0 40px;
    }
    .bottomtext {
      color: #5d5d5d;
      font-size: 14px;
      font-weight: 600;
      padding: 0 40px;
    }
  }
}
.card {
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: 0 -1px 8px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 2vh;
  .ant-card-head {
    padding: 0 !important;
    margin: 0 10px 0 5px;
    min-height: 6vh;
    display: flex;
    align-items: center;
    border-bottom: none !important;
    .ant-card-head-wrapper {
      display: flex;
      flex-direction: row-reverse;
      height: 7vh;
      width: 100%;
      .ant-card-head-title {
        display: flex;
        align-items: center;
        padding: 0;
        font-weight: 600;
        height: 7vh;
        border-bottom: 1px solid #e8e8e8;
        margin: 0 0 0 10px;
      }
      .ant-card-extra {
        .yellow-border-title {
          width: 4px;
          height: 6vh;
          background-color: #ff6b00;
          border-radius: 5px;
        }
      }
    }
  }
  .ant-card-body {
    background: rgba(240, 240, 240, 0.247) !important;
    padding: 12px 24px;
    .title-container {
      margin-bottom: 1vh;
    }
    .item-title {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 600;
      font-size: 13px;
    }
    .item-subtitle {
      color: rgba(7, 7, 7, 0.397);
      font-weight: 600;
      font-size: 13px;
    }
    .item-value {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }
    .item-edit-measurement {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      font-size: 12px;
    }
    p {
      margin: 0;
    }
    input {
      margin-right: 10px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }
  }
}
.body-composition-card {
  margin: 1vh 0 2vh 0;
  .ant-card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .body-composition-item {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      input {
        width: 80px;
      }
    }
  }
}
.measurements-card {
  .measurements-item {
    margin-bottom: 3vh;
    input {
      width: 45%;
    }
  }
  .title-container {
    display: flex;
    margin-bottom: 0 !important;
    .item-title {
      margin-right: 1vh;
    }
  }
}
.body-fat-card {
  .body-fat-method {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 2vh;
  }
  button {
    margin: 15px 0;
    text-align: start;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 600;
      font-size: 13px;
    }
  }
  input {
    width: 80px;
    margin: 10px 0;
  }
}
.overhead-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 10vh;
  padding: 0 2vh;
  img {
    width: 38px;
    margin-right: 1vh;
  }
  .info-container {
    margin: 1vh 1vh;
    button {
      padding: 0 5px;
      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 600;
        font-size: 12px;
      }
      i {
        margin: 0 5px;
      }
    }
  }
  .editable {
    width: 88%;
    button {
      width: 100%;
    }
  }
}
.goal-card {
  .info-title {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    font-size: 13px;
  }
  .info-description {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 16px;
  }
}
.level-card {
  .overhead-card {
    padding: 0 2vh 0 1vh !important;
    img {
      width: 56px !important;
    }
    .info-title {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      font-size: 16px;
    }
    .info-description {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 600;
      font-size: 13px;
    }
    button {
      width: 100%;
    }
  }
}
.footer-edit {
  min-height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  button {
    margin: 0 10px;
    width: 160px;
    border-radius: 20px;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
  }
  .default {
    border: solid 1px #ff6b00;
    color: #ff6b00;
  }
  .primary {
    border: none;
    color: white;
    background: rgb(255, 70, 19);
    background: linear-gradient(90deg, rgba(255, 70, 19, 1) 0%, rgba(255, 107, 0, 1) 100%);
  }
}
