.wrap {
  position: relative;
  &.short {
    width: 200px;
  }
}

.mat-label {
  display: block;
  font-size: 14px;
  transform: translateY(25px);
  color: #717271;
  transition: all 0.5s;
  height: 18px;
}

.mat-input {
  position: relative;
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 0 4px;
  font-size: 14px;
  z-index: 1;
  padding-right: 79px;
}

.mat-div {
  position: relative;
  margin-bottom: 4px;
  hyphens: auto;
}

.mat-div:after,
.mat-div:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #979797 0%, #c4c4c4 100%);
  bottom: 0;
  left: 0;
  transition: all 0.5s;
  padding: 1.2px;
}

.mat-div::after {
  background: linear-gradient(90deg, #ff6b00 0%, #ffa401 100%);
  transform: scaleX(0);
}

.is-active::after {
  transform: scaleX(1);
}

.is-active .mat-label {
  color: #979797;
}

.is-completed .mat-label {
  font-size: 12px;
  transform: translateY(0);
}

.wrap-icon-feedback {
  position: absolute;
  top: 27px;
  right: 5px;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.suffix {
  position: absolute;
  left: 0px;
  top: 28px;
  color: #555;
  padding-left: 10px;
  z-index: 0;
  hyphens: auto;
}

/* SELECT STYLES*/
.wrap {
  .ant-select,
  .ant-input {
    width: 100%;
  }
  .ant-select-selection,
  .ant-input {
    border: none;
    border-radius: 0;
    position: relative;
    width: 100%;
    outline: none;
    background: transparent;
  }

  .is-active {
    .ant-select-selection,
    .ant-input {
      border: none;
      background: transparent;
      box-shadow: none;
      .ant-select-arrow .ant-select-arrow-icon {
        color: #ff6b00;
      }
    }
    .ant-calendar-picker-icon {
      color: #ff6b00;
    }
  }
  .ant-select-selection__rendered,
  .ant-input {
    margin: 0px;
    padding: 0px;
  }
  /* DATE-PICKER STYLES*/

  .ant-calendar-picker {
    width: 100%;
  }

  &.mat-multi-select {
    .ant-select {
      .ant-select-selection--multiple {
        padding: 0px 7px 6px 6px;
        border: 2px solid #ff6b00;
        .ant-select-selection__rendered {
          ul {
            li {
              background: rgba(196, 196, 196, 0.3);
              border-radius: 5px;
              padding: 6px 26px 6px 9px;
              height: 37px;
              margin-top: 8px;
              margin-right: 8px;

              .ant-select-selection__choice__remove {
                color: #ff6b00;
                font-size: 20px;

                i > svg > path {
                  stroke: #ff6b00;
                  stroke-width: 100;
                }
              }

              &.ant-select-search {
                background-color: #ffffff;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 720px) {
    .mat-label,
    .mat-input {
      font-size: 16px !important;
    }
  }
}
